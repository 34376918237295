import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Widget } from '@typeform/embed-react'

const ContactPage = () => (
  <Layout>
  <SEO title="Contact" />
  <Widget id="LsunBzR3" style={{ width: '50%' }} className="my-form" hideHeaders="true" hideFooter="true" />
  </Layout>
)

export default ContactPage